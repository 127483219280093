// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;400;500;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&family=Lateef&family=Markazi+Text:wght@400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

/************************************/
$colors: (
  dark: #121318,
  orangeLight: #f7eccc,
  orangeDark: #f7a420,
  redLight: #cd250e,
  redDark: #801e08,
  greenLight: #1ecd0e,
  greenDark: #086b10,
  white: #ffffff,
  black-100: #000000,
  black-90: #121318,
  black-80: #131313,
  black-70: #232228,
  black-60: #37343b,
  black-50: #53505b,
  success: #5bcc5b,
  danger: #f521c0,
  neon: #26ddff,
  neonDanger: #f521c0,
);
// //gets a color value from color map, defined in colorDefinitions
@function color($colorName) {
  @return map-get($colors, $colorName);
}

html {
  font-size: 16px;
  // font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
}

/* 1.25 dpr */
@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  /* Retina-specific stuff here */
  html {
    // font-size: 24px;
  }
}
/* 1.3 dpr */
@media (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 124.8dpi) {
  /* Retina-specific stuff here */
}
/* 1.5 dpr */
@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
  /* Retina-specific stuff here */
}
@media screen and (max-device-width: 768px) {
  // and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.89) // and (min-resolution: 192dpi)
  html {
    font-size: 16px !important;
  }
}
@media screen and (min-device-width: 768px) {
  // and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.89) // and (min-resolution: 192dpi)
  html {
    font-size: 16px !important;
  }
}
@media screen and (min-device-width: 1200px) {
  // and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.89) // and (min-resolution: 192dpi)
  html {
    font-size: 18px !important;
  }
}
@media screen and (min-device-width: 2559px) {
  // and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.89) // and (min-resolution: 192dpi)
  html {
    font-size: 20px !important;
  }
}
@media screen and (min-device-width: 5000px) {
  // and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.89) // and (min-resolution: 192dpi)
  html {
    font-size: 36px !important;
  }
}

.rtl {
  direction: rtl !important;
}
// .rtl .rtlFont,
// .rtl.rtlFont {
//   font-family: "Markazi Text", serif;
// }
// .Persian {
//   font-family: "El Messiri", sans-serif;
// }
// .Messiri {
//   font-family: "El Messiri", sans-serif;
// }
// .Karla {
//   font-family: "Karla", sans-serif !important;
// }
.right-0 {
  right: 0 !important;
}
.right-auto {
  right: auto !important;
}
.left-0 {
  left: 0 !important;
}
.left-auto {
  left: auto !important;
}
.w-max {
  width: max-content !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #fff;
  -webkit-text-fill-color: #f0ce89;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight:900 !important;
  background-color: #1e1f31 !important;
  font-size: 16px;
  color: #fff !important;
  line-height: 1.15rem !important;
}
body * {
  // font-family: "Lato", sans-serif !important;
  // font-weight:900 !important;
}
body::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
  background: rgb(0, 0, 0);
}

body::-webkit-scrollbar-thumb {
  background-color: black; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 4px solid #464646; /* creates padding around scroll thumb */
}

/* Chrome, Safari, Edge, Opera */
.noArrow input::-webkit-outer-spin-button,
.noArrow input::-webkit-inner-spin-button,
.noArrow input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.noScrollbar,
.MuiDrawer-root.MuiDrawer-docked > div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.noScrollbar::-webkit-scrollbar,
.MuiDrawer-root.MuiDrawer-docked > div::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

.noselect,
span,
p,
div,
strong,
td,
li,
button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.yesselect,
.yesselect span,
.yesselect p,
.yesselect div,
.yesselect strong,
.yesselect td,
.yesselect li {
  -webkit-touch-callout: text !important;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}
